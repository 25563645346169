//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MediaPick from '@/components/Gallery/MediaPick.vue'
export default {
    name: 'ProfileCoverPhoto',
    components: { MediaPick },
    props: [
      'url',
      'isOwner',
      'user_id',
    ],
    data() {
      return {
        src: this.url,
        file: null,
        isLoading: false,
        showDialog:false,
        coverPhoto:require('@/assets/default_cover2.webp'),
      }
    },
    created(){
      this.loadImage(this.user_id)
    },
    methods: {
      onImgError() {
        this.src = require('@/assets/default_cover2.webp')
        this.isLoading = false
      },
      loadImage(id) {
        this.isLoading = true
        this.payload = {
          id: id
        }
          this.$store.dispatch('profile/getCoverPhoto', this.payload)
          .then((response) =>{
            if(response == 'success'){
              this.coverPhoto = this.$store.state.profile.cover_photo
            }
          })
          this.isLoading = false;
      },
      selectPhoto() {
        this.$refs.filePicker.pickFiles();
      },
      chooseGallery(){
        this.showDialog = true;
      },
      updateMedia(id){
        this.$q.dialog({
          message:'Are you sure you want to use this photo?',
          cancel: true,
        })
        .onOk(() => {
          this.$store.dispatch('profile/UpdateCoverPhotoByID',id)
            .then((r) => {
              if(r.type == 'success'){
                this.$q.notify({
                  message: 'Successfully update cover photo',
                  color:'positive',
                  icon: 'check_circle'
                })
              }
              location.reload()
            })
        })
        .onCancel(() =>{})
        .onDismiss(() => {})
      },
      uploadImage() {
            if (!this.file) {
                return;
            }
            this.$q.dialog({
              title:'Change Cover Photo',
              message: 'Are you sure you want to use this as your cover photo?',
              cancel:true,
            }).onOk(() => {
              var formData = new FormData();
              formData.append('file', this.file);
              formData.append('entity_id', this.entity_id);
              formData.append('entity_type_id', this.entity_type_id);
              var payload = {
                  data: formData
              }
              this.isLoading = true;
                this.$store.dispatch('profile/uploadCoverPhoto',payload)
                .then((status) =>{
                  if(status == 'success'){
                    this.$q.notify({
                      message:"Cover Photo successfuly updated",
                      color:'positive',
                      icon: 'check_circle'
                    })
                    location.reload()
                  }else{
                    this.src = this.$store.state.app.defaultCoverPhoto
                    this.$q.notify({
                      message:'Unable to change your cover photo.',
                      color: 'negative',
                      icon: 'error'
                    })
                  }
                })
            
              this.isLoading = false;
            })
            .onCancel(() => {
              //
            })
            .onDismiss(() => {
              //
            })
        }      
    }
}

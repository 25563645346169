//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'MediaPick',
    components: {
        EmptyMessage: () => import('@/components/EmptyMessage'),
    },
    props:[
        'user_id',
        'entity_id',
    ],
    data(){
        return {
            medias: [],
            pagination: null,
            page:1,
            files: null,
            isEmpty: true,
        }
    },
    methods:{
        getGallery(url) {
            this.isLoading = true;
            this.$http({
                url: url,
                method: "GET"
            }).then((response) => {
                if (response.data['status'] == 'gallery_retrieved') {
                    this.isEmpty = false;
                    response.data['body']['medias'].forEach((media) => {
                        this.medias.push(media);
                    });
                    this.pagination = response.data['body']['pagination'];
                }
                if (response.data['status'] == 'gallery_empty') {
                    this.isEmpty = true;
                }
                if (this.isOnPreview) {
                    this.medias = this.medias.splice(0, 10);
                    this.isOnPreview = false;
                }
            })
            .catch(err =>{
                return err
            })
            this.isLoading = false;            
        },

        /**
         * Loads user gallery depending on the supplied user_id on the prop.
         */
        loadUserGallery() {
            this.getGallery('profiles/' + this.user_id + '/gallery?page=' + this.page);
        },

        /**
         * Loads an entity gallery depending on the entity_id on the prop.
         */
        loadEntityGallery() {
            this.getGallery('entities/' + this.entity_id + '/gallery?page=' + this.page);
        },
        load() {
            if (this.user_id) {
                this.loadUserGallery();
            }

            if (this.entity_id) {
                this.loadEntityGallery();
            }
        },

        /**
         * Send all the images to the send function.
         */
        uploadImages() {
            this.isUploading = true;
            if (this.numberOfFiles > 1) {
                this.files.forEach((file) => {
                    this.send(file);
                });
            } else {
                this.send(this.files[0]);
            }            
        },
        convertMediaToUrl(media) {
            return  process.env.VUE_APP_APISERVER + 'gb_medias/' + media.user_id + '/' + media.file_name + '.' + media.type;
        },

        selectMedia(id){
            this.$emit('selectedMedia',id)
        }
    },
    created(){
        this.load()
    }
}
